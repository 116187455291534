





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { contactUs } from '@/scripts/api/login';
import { errHandle, showMessage } from '@/scripts/utils/library';

export default Vue.extend({
    data() {
        return {
            formData: {
                name: '',
                email: '',
                message: '',
            },
            formRules: {
                name: [
                    { required: true, message: '請輸入您的姓名', trigger: ['blur'] },
                ],
                email: [
                    { required: true, message: '請輸入電郵', trigger: ['blur'] },
                ],
                message: [
                    { required: true, message: '請輸入您的留言', trigger: ['blur'] },
                ],
            },
            submitting: false,
        };
    },
    methods: {
        onSubmit() {
            (this.$refs.form as any).validate((valid: boolean, failedFields: object) => {
                if (valid) {
                    // valid ok
                    this.submitting = true;
                    contactUs(this.formData).then((response) => {
                        console.debug('submit success');
                        showMessage(this, '多謝你您的留言，我們會盡快聯絡你。');
                        (this.$refs.form as any).resetFields();
                    }).catch((error) => {
                        console.debug('submit error:' + error);
                        errHandle(this, error);
                    }).finally(() => {
                        console.debug('submit finally');
                        this.submitting = false;
                    });
                } else {
                  errHandle(this, failedFields);
                  return false;
                }
            });
        },
    },
});
