






























































































































































import { Component, Vue } from 'vue-property-decorator';
import ContactUsForm from '@/components/ContactUs.vue'; // @ is an alias to /src
import CompanyDetail from '@/components/CompanyDetail.vue';
import Calculator from '@/components/Calculator.vue';
// import MarqueeBar from '@/components/MarqueeBar.vue';

export default {
  components: {
    ContactUsForm,
    CompanyDetail,
    Calculator,
    // MarqueeBar,
  },
};
